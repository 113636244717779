import React from 'react'

import Layout from '../../components/layout'
import CheckoutDelivery from '../../components/CheckoutDelivery'

export default () => (
  <Layout>
    <h1 className="h2">Naar waar mag deze bon gestuurd worden?</h1>

    <div className="row">
      <div className="col-12">

        <CheckoutDelivery />

      </div>
    </div>
  </Layout>
)
