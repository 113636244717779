import React, { Component } from 'react';
import { navigate } from "@reach/router"

import CartContext from '../Context/CartContext'

const SAME_ADDRESS = 'use_same_address'
const DIFF_ADDRESS = 'use_diff_address'
const PICKUP_IN_STORE = 'pickup_in_store'

const isValid = (cart) => {
  if (cart && cart.requiresAddress()) {
    if (cart.use_same_address === PICKUP_IN_STORE) {
      return true
    }
    if (cart.use_same_address === SAME_ADDRESS) {
      return true
    }
    if (cart.use_same_address === DIFF_ADDRESS && cart.deliveryLastName && cart.deliveryStreet && cart.deliveryStreetnr && cart.deliveryPostalcode && cart.deliveryCity) {
      return true
    }
    return false
  } else if (cart && !cart.requiresAddress()) {
    return true
  }
  return false
}

export default class CheckoutDelivery extends Component {
  cart = null

  state = {
    use_same_address: { value: SAME_ADDRESS, changed: false },
    deliveryFirstName: { value: null, changed: false },
    deliveryLastName: { value: null, changed: false },
    deliveryStreet: { value: null, changed: false },
    deliveryStreetnr: { value: null, changed: false },
    deliveryPostalcode: { value: null, changed: false },
    deliveryCity: { value: null, changed: false },
    deliveryCountry: { value: 'BE', changed: false },
    changed: false,
    error: false
  }

  set = (e) => {
    this.setState({
      changed: true,
      [e.target.name]: {
        value: e.target.value,
        changed: true
      }
    })
  }

  check = (e, setCmd) => {
    setCmd(e.target.name, e.target.value)
    if (e.target.value === SAME_ADDRESS) {
      setCmd('deliveryFirstName', this.state.deliveryFirstName.value)
      setCmd('deliveryLastName', this.state.deliveryLastName.value)
      setCmd('deliveryStreet', this.state.deliveryStreet.value)
      setCmd('deliveryStreetnr', this.state.deliveryStreetnr.value)
      setCmd('deliveryPostalcode', this.state.deliveryPostalcode.value)
      setCmd('deliveryCity', this.state.deliveryCity.value)
      setCmd('deliveryCountry', this.state.deliveryCountry.value)

      this.setState({
        deliveryFirstName: { value: null, changed: true },
        deliveryLastName: { value: null, changed: true },
        deliveryStreet: { value: null, changed: true },
        deliveryStreetnr: { value: null, changed: true },
        deliveryPostalcode: { value: null, changed: true },
        deliveryCity: { value: null, changed: true },
        deliveryCountry: { value: 'BE', changed: true },
        changed: true
      })
    } else {
      this.setState({ changed: true })
    }
  }

  save = (e, updateCmd, setCmd) => {
    e.preventDefault()

    if (this.state.use_same_address.changed) setCmd('use_same_address', this.state.use_same_address.value)
    if (this.state.deliveryFirstName.changed) setCmd('deliveryFirstName', this.state.deliveryFirstName.value)
    if (this.state.deliveryLastName.changed) setCmd('deliveryLastName', this.state.deliveryLastName.value)
    if (this.state.deliveryStreet.changed) setCmd('deliveryStreet', this.state.deliveryStreet.value)
    if (this.state.deliveryStreetnr.changed) setCmd('deliveryStreetnr', this.state.deliveryStreetnr.value)
    if (this.state.deliveryPostalcode.changed) setCmd('deliveryPostalcode', this.state.deliveryPostalcode.value)
    if (this.state.deliveryCity.changed) setCmd('deliveryCity', this.state.deliveryCity.value)
    if (this.state.deliveryCountry.changed) setCmd('deliveryCountry', this.state.deliveryCountry.value)

    if (isValid(this.cart)) {
      if (this.state.changed) {
        updateCmd()
          .then((cart) => {
            navigate(`/checkout/overview`)
          })
      } else {
        navigate(`/checkout/overview`)
      }
    } else {
      this.setState({ error: true })
    }
  }

  render() {
    const { error } = this.state

    return (
      <CartContext.Consumer>
        {({ cartid, cart, get, set, update }) => {
          if (cart) {
            this.cart = cart

            if (!cart.requiresAddress()) {
              navigate(`/checkout/overview`)
            }
            else {
              return (
                <form onSubmit={e => this.save(e, update, set)}>
                  <div className="card mb-2">
                    <div className="card-body">
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="use_same_address" value={SAME_ADDRESS} checked={cart.use_same_address === SAME_ADDRESS} id="use_same_address" onChange={e => this.check(e, set)} />
                        <label className="form-check-label" htmlFor="use_same_address">Deze bon mag naar mij verstuurd worden</label>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="use_same_address" value={DIFF_ADDRESS} checked={cart.use_same_address === DIFF_ADDRESS} id="use_diff_address" onChange={e => this.check(e, set)} />
                        <label className="form-check-label" htmlFor="use_diff_address">Deze bon mag rechtstreeks naar de gelukkige verstuurd worden</label>
                      </div>
                      <div className="form-check">
                        <input className="form-check-input" type="radio" name="use_same_address" value={PICKUP_IN_STORE} checked={cart.use_same_address === PICKUP_IN_STORE} id="pickup_in_store" onChange={e => this.check(e, set)} />
                        <label className="form-check-label" htmlFor="pickup_in_store">Ophaling in winkel - van woensdag t.e.m. zaterdag van 10u-14u</label>
                      </div>
                    </div>
                  </div>

                  {cart.use_same_address === DIFF_ADDRESS ? (
                    <div className="card mb-2">
                      <div className="card-body">
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label" htmlFor="deliveryFirstName">Voornaam</label>
                          <div className="col-sm-10">
                            <input type="text" className="form-control" id="deliveryFirstName" name="deliveryFirstName" defaultValue={cart.deliveryFirstName} placeholder="" onChange={e => this.set(e)} />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label" htmlFor="deliveryLastName">Naam</label>
                          <div className="col-sm-10">
                            <input type="text" className="form-control" id="deliveryLastName" name="deliveryLastName" defaultValue={cart.deliveryLastName} placeholder="" onChange={e => this.set(e)} />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label" htmlFor="inputStreet">Straat</label>
                          <div className="col-sm-10">
                            <input type="text" className="form-control" id="inputStreet" name="deliveryStreet" defaultValue={cart.deliveryStreet} placeholder="" onChange={e => this.set(e)} />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label" htmlFor="inputStreetnr">Nummer</label>
                          <div className="col-sm-10">
                            <input type="text" className="form-control" id="inputStreetnr" name="deliveryStreetnr" defaultValue={cart.deliveryStreetnr} placeholder="" onChange={e => this.set(e)} />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label" htmlFor="inputPostalcode">Postcode</label>
                          <div className="col-sm-10">
                            <input type="text" className="form-control" id="inputPostalcode" name="deliveryPostalcode" defaultValue={cart.deliveryPostalcode} placeholder="" onChange={e => this.set(e)} />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label" htmlFor="inputCity">Gemeente</label>
                          <div className="col-sm-10">
                            <input type="text" className="form-control" id="inputCity" name="deliveryCity" defaultValue={cart.deliveryCity} placeholder="" onChange={e => this.set(e)} />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-sm-2 col-form-label" htmlFor="inputCountry">Land</label>
                          <div className="col-sm-10">
                            <select className="form-control" name="deliveryCountry" defaultValue={cart.deliveryCountry || 'BE'} onChange={e => this.set(e)}>
                              <option value="BE">België</option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}

                  {error ? (
                    <div className="alert alert-danger">
                      <p className="p-0 m-0">Gelieve alle velden correct in te vullen</p>
                    </div>
                  ) : null}

                  <div className="row">
                    <div className="col-md-4 offset-md-8">
                      <button type="submit" className="btn btn-primary btn-block">Naar betalen</button>
                    </div>
                  </div>
                </form>
              )
            }
          }

          return null
        }}
      </CartContext.Consumer>
    );
  }
}
